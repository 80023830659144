<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5"> Size exceeded </v-card-title>
        <v-card-text>Please upload file less than 2MB.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close"> Close </v-btn>
          <v-btn text @click="close"> Accept </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
export default {
  name: 'dialog-message-size',
  props: {
    dialog: Boolean,
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    }
  },
}
</script>
