<template>
  <div id="user-view">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows class="user-tabs">
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items id="user-tabs-content" v-model="tab" class="mt-5 pa-1">
      <v-tab-item>
        <account />
      </v-tab-item>

      <v-tab-item>
        <security />
      </v-tab-item>

      <v-tab-item>
        <notification />
      </v-tab-item>

      <v-tab-item>
        <activity />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiCreditCardOutline,
  mdiMathLog,
  mdiLoginVariant,
} from '@mdi/js'
import Account from './components/Account/Account.vue'
import Security from './components/Security/Security.vue'
import Notification from './components/Notifications/Notification.vue'
import Activity from './components/Activity/Activity.vue'

export default {
  components: { Account, Security, Notification, Activity },
  data() {
    return {
      tab: '',
      tabs: [
        { title: 'Account', icon: mdiAccountOutline },
        { title: 'Security', icon: mdiLockOpenOutline },
        { title: 'Notifications', icon: mdiBellOutline },
        // { title: 'Activity', icon: mdiLoginVariant },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
