<template>
  <v-card flat class="pa-3">
    <v-card-title>
      <v-icon size="26" class="text--primary me-3">
        {{ icons.mdiChartTimelineVariant }}
      </v-icon>
      <span>Activity</span>
    </v-card-title>

    <!-- Activity -->
    <v-card-text>
      <v-switch
        v-model="settings.notify_full_report"
        hide-details
        class="mt-0"
        @change="
          saveSettingsData({
            notify_keyword_research: !settings.notify_full_report,
            notify_semantic_report: !settings.notify_full_report,
            notify_serp_report: !settings.notify_full_report,
            notify_full_report: !settings.notify_full_report,
          })
        "
      >
        <template #label>
          <span class="text-sm ms-2">Turn on notifications for Reports</span>
        </template>
      </v-switch>
    </v-card-text>

    <v-card-title class="mt-2">
      <v-icon size="26" class="text--primary me-3">
        {{ icons.mdiEmailOutline }}
      </v-icon>
      <span>Application</span>
    </v-card-title>

    <!-- Application -->
    <v-card-text>
      <v-switch
        v-model="isDark"
        hide-details
        class="mt-3"
        @change="
          saveSettingsData({
            dark_theme: isDark,
          })
        "
      >
        <template #label>
          <span class="text-sm ms-2">Dark Theme</span>
        </template>
      </v-switch>
    </v-card-text>

    <!-- <v-card-text>
      <v-btn color="primary" class="me-3 mt-5" @click="saveSettingsData"> Save changes </v-btn>
      <v-btn color="secondary" outlined class="mt-5"> Cancel </v-btn>
    </v-card-text> -->
  </v-card>
</template>

<script>
import store from '@/store'
import { computed, onBeforeMount, meh, ref, unref, watch } from '@vue/composition-api'
import { mdiChartTimelineVariant, mdiEmailOutline } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  props: {},
  setup(props) {
    const { isDark } = useAppConfig()

    const loading = ref(false)
    const settings = ref({
      dark_theme: false,
      notify_keyword_research: false,
      notify_semantic_report: false,
      notify_serp_report: false,
      notify_full_report: false,
    })

    onBeforeMount(() => {
      getSettingsData()
    })

    const getSettingsData = async () => {
      await store
        .dispatch('settings/getSettings')
        .then(resp => {
          settings.value = resp.data
        })
        .finally(() => {
          loading.value = false
        })
    }

    const saveSettingsData = async data => {
      await store
        .dispatch('settings/saveSettings', data)
        .then(resp => {})
        .finally(() => {
          loading.value = false
        })
    }

    return {
      isDark,
      loading,
      settings,
      icons: { mdiChartTimelineVariant, mdiEmailOutline },
      getSettingsData,
      saveSettingsData,
    }
  },
}
</script>
