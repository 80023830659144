var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"text--primary me-3",attrs:{"size":"26"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChartTimelineVariant)+" ")]),_c('span',[_vm._v("Activity")])],1),_c('v-card-text',[_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.saveSettingsData({
          notify_keyword_research: !_vm.settings.notify_full_report,
          notify_semantic_report: !_vm.settings.notify_full_report,
          notify_serp_report: !_vm.settings.notify_full_report,
          notify_full_report: !_vm.settings.notify_full_report,
        })}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-sm ms-2"},[_vm._v("Turn on notifications for Reports")])]},proxy:true}]),model:{value:(_vm.settings.notify_full_report),callback:function ($$v) {_vm.$set(_vm.settings, "notify_full_report", $$v)},expression:"settings.notify_full_report"}})],1),_c('v-card-title',{staticClass:"mt-2"},[_c('v-icon',{staticClass:"text--primary me-3",attrs:{"size":"26"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEmailOutline)+" ")]),_c('span',[_vm._v("Application")])],1),_c('v-card-text',[_c('v-switch',{staticClass:"mt-3",attrs:{"hide-details":""},on:{"change":function($event){return _vm.saveSettingsData({
          dark_theme: _vm.isDark,
        })}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-sm ms-2"},[_vm._v("Dark Theme")])]},proxy:true}]),model:{value:(_vm.isDark),callback:function ($$v) {_vm.isDark=$$v},expression:"isDark"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }