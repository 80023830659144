<template>
  <div class="wait" v-if="loading">
    <v-progress-circular indeterminate color="primary" size="60"></v-progress-circular>
  </div>

  <v-card flat class="pa-3 pb-6" v-else>
    <v-card-text class="d-flex">
      <div class="col-image">
        <v-avatar rounded size="100">
          <div class="preview-image" @click="chooseFile">
            <v-img
              v-if="!uploading"
              :src="filePreview ? filePreview : '@/assets/images/avatars/1.png'"
              max-height="100px"
              max-width="100px"
            />
            <v-progress-circular v-else indeterminate color="primary" size="60"></v-progress-circular>
          </div>
        </v-avatar>

        <input
          class="form-control form-control-lg input-file"
          ref="fileInput"
          type="file"
          accept=".jpeg,.png,.jpg,.gif"
          :hidden="true"
          @input="selectImgFile"
          @change="uploadFile"
        />
        <v-btn color="primary" text @click="chooseFile"> Upload file</v-btn>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col md="6" cols="12">
            <label for="">Company</label>
            <v-chip class="ma-2 px-5">
              {{ account ? account.company_name : '' }}
            </v-chip>
          </v-col>
          <v-col md="6" cols="12">
            <label for="">Email</label>
            <v-chip class="ma-2 px-5">
              {{ user.email }}
            </v-chip>
          </v-col>
          <v-col md="6" cols="12">
            <v-text-field v-model="profile.first_name" label="First Name" dense outlined />
          </v-col>
          <v-col md="6" cols="12">
            <v-text-field v-model="profile.last_name" label="Last Name" dense outlined />
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field v-model="profile.address" label="Address" dense outlined></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field v-model="profile.phone" label="Phone" dense outlined></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field v-model="profile.city" label="City" dense outlined></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field v-model="profile.country" label="Country" dense outlined></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field v-model="profile.zip" label="Zip" dense outlined></v-text-field>
          </v-col>

          <v-col cols="12">
            <label for="">Roles:</label>
            <v-chip class="ma-2" v-for="(role, index) in roles" :key="index">
              {{ role.name }}
            </v-chip>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" class="me-3 mt-4" @click.prevent="updateData">
              <span v-if="saving === false">Save changes</span>
              <span v-else>
                <v-progress-circular indeterminate style="margin-right: 10px"></v-progress-circular>
                <span>Saving...</span>
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <dialog-message-size :dialog.sync="dialogSize" />
  </v-card>
</template>

<script>
import { computed, onBeforeMount, meh, ref, unref, watch } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
import axios from '@/plugins/axios'
import DialogMessageSize from './components/DialogMessageSize'
//store
import store from '@/store'

export default {
  components: {
    DialogMessageSize,
  },
  setup() {
    const loading = ref(true)
    const uploading = ref(false)
    const percentage = ref(0)
    const saving = ref(false)
    const profile = ref({})
    const account = ref({})
    const roles = ref([])
    const user = ref({})
    const filePreview = ref(null)
    const file = ref('')
    const canEdit = ref(false)
    const errors = ref(null)
    const dialogSize = ref(false)
    const fileInput = ref(null)

    // store getters
    const hasUserRole = role => {
      return store.getters['auth/hasUserRole'](role)
    }
    const hasUserPermission = permission => {
      return store.getters['auth/hasUserPermission'](permission)
    }
    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }

    onBeforeMount(() => {
      getUserData()
    })

    //methods
    const chooseFile = () => {
      fileInput.value.click()
      canEdit.value = true
    }
    const selectImgFile = e => {
      let avatarInput = fileInput.value
      let imgFile = avatarInput.files
      console.log(imgFile)

      let sizeMB = avatarInput.files[0].size / 1048576 // 1024 * 1024
      if (sizeMB >= 2) {
        dialogSize.value = true
      } else {
        file.value = avatarInput.files[0]
        if (imgFile && imgFile[0]) {
          let reader = new FileReader()
          reader.onload = e => {
            filePreview.value = e.target.result
          }
          reader.readAsDataURL(imgFile[0])
          // avatarInput.value = imgFile[0]
        }
      }
    }
    const getUserData = async () => {
      loading.value = true
      return await store
        .dispatch('auth/getUser')
        .then(response => {
          profile.value = { ...response.data.profile }
          user.value = { ...response.data }
          roles.value = [...response.data.roles]
          account.value = { ...profile.value.account }
          if (profile.value.avatar) {
            filePreview.value = profile.value.avatar
          }
        })
        .finally(() => {
          loading.value = false
        })
    }
    const uploadFile = async () => {
      let avatarInput = fileInput.value
      let sizeMB = avatarInput.files[0].size / 1048576 // 1024 * 1024

      if (sizeMB <= 2) {
        const formData = new FormData()
        formData.append('avatar', file.value)
        const headers = { 'Content-Type': 'multipart/form-data' }

        const onUploadProgress = event => {
          percentage.value = Math.round((100 * event.loaded) / event.total)
        }

        uploading.value = true
        await axios
          .post(`/user/avatar`, formData, { headers }, onUploadProgress)
          .then(response => {
            profile.value.avatar = response.data.file_path
          })
          .catch(error => {
            console.log(error)
            errors.value = error.response.data.errors
          })
          .finally(() => {
            uploading.value = false
            percentage.value = 0
          })
      }
    }
    const updateData = async () => {
      saving.value = true
      const data = {
        profile: profile.value,
      }
      await store
        .dispatch('auth/updateProfile', data)
        .then(response => {
          if (response && response.data) {
            setSnackbar({
              status: true,
              text: 'Successfully saved data.',
              color: 'primary',
            })
          }
        })
        .catch(error => {
          console.log(error.response)
          errors.value = error.response.data.error

          setSnackbar({
            status: true,
            text: 'Data could not be saved.',
            color: 'error',
          })
        })
        .finally(() => {
          saving.value = false
        })
    }

    return {
      loading,
      uploading,
      percentage,
      saving,
      profile,
      account,
      roles,
      user,
      filePreview,
      file,
      canEdit,
      errors,
      dialogSize,
      fileInput,

      hasUserRole,
      hasUserPermission,
      setSnackbar,

      chooseFile,
      selectImgFile,
      getUserData,
      uploadFile,
      updateData,
    }
  },
}
</script>

<style scoped lang="css">
.wait {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.col-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.preview-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-position: center center;
  background-size: cover;
}
</style>
